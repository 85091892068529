import React, {useEffect, useRef, useState} from 'react';
import {Page} from "../../Interfaces/IPage";
import {Link} from "react-router-dom";

interface OwlCarouselProps {
  backgroundImage: any;
  slides: Page[];
}

const OwlCarousel: React.FC<OwlCarouselProps> = ({backgroundImage, slides}) => {

  // const [slides, setSlides] = React.useState < Page[] | null > (null);
  const [loaded, setLoaded] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {

    if (loaded || !window.$ || typeof window === 'undefined') {
      return;
    }

    if (slides) {
      require('owl.carousel');
      const $ = window.$;
      const $carousel = $(carouselRef.current);

      if ($carousel.length) {
        // :: 4.0 Sliders Active Code
        if ($.fn.owlCarousel) {
          var welcomeSlide = $('.hero-slides');
          welcomeSlide.owlCarousel({
            items: $carousel.length,
            margin: 0,
            loop: true,
            nav: true,
            dots: true,
            autoplay: true,
            autoplayTimeout: 4000,
            smartSpeed: 3000,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut'
          });

          // // Adicionar eventos de mouse para pausar e retomar o loop na div com a classe 'hero-slides-content'
          $carousel.on('mouseenter', '.owl-next', () => {
            $carousel.trigger('stop.owl.autoplay');
          });

          $carousel.on('mouseenter', '.owl-prev', () => {
            $carousel.trigger('stop.owl.autoplay');
          });

          $carousel.on('mouseleave', '.owl-next', () => {
            $carousel.trigger('play.owl.autoplay', [5000]);
          });

          $carousel.on('mouseleave', '.owl-prev', () => {
            $carousel.trigger('play.owl.autoplay', [5000]);
          });

          // welcomeSlide.on('translate.owl.carousel', function () {
          //     var slideLayer = $("[data-animation]");
          //     slideLayer.each(function () {
          //         var anim_name = $(this).data('animation');
          //         $(this).removeClass('animated ' + anim_name).css('opacity', '0');
          //     });
          // });
          //
          // welcomeSlide.on('translated.owl.carousel', function () {
          //     var slideLayer = welcomeSlide.find('.owl-item.active').find("[data-animation]");
          //     slideLayer.each(function () {
          //         var anim_name = $(this).data('animation');
          //         $(this).addClass('animated ' + anim_name).css('opacity', '1');
          //     });
          // });
          //
          // $("[data-delay]").each(function () {
          //     var anim_del = $(this).data('delay');
          //     $(this).css('animation-delay', anim_del);
          // });
          //
          // $("[data-duration]").each(function () {
          //     var anim_dur = $(this).data('duration');
          //     $(this).css('animation-duration', anim_dur);
          // });
          //
          // var dot = $('.hero-slides .owl-dot');
          // dot.each(function () {
          //     var index = $(this).index() + 1;
          //     if (index < 10) {
          //         $(this).html('0').append(index);
          //     } else {
          //         $(this).html(index);
          //     }
          // });

          setLoaded(true);
        }
      }

    }


  }, []);

  const handleMouseOver = () => {
    if (carouselRef.current) {
      const $carousel = $(carouselRef.current);
      $carousel.trigger('stop.owl.autoplay');
    }
  };

  const handleMouseOut = () => {
    if (carouselRef.current) {
      const $carousel = $(carouselRef.current);
      $carousel.trigger('play.owl.autoplay', [5000]);
    }
  };

  return (
    <div className="hero-slides owl-carousel" ref={carouselRef}>

      {slides?.length === 0 && (
        <div className="single-hero-slide d-flex align-items-center justify-content-center">

          <div className="slide-img bg-img"
               style={{"backgroundImage": `url(${backgroundImage})`}}></div>

          <div className="container">

            <div className="row justify-content-center">

              <div className="col-12 col-lg-9" style={{"textAlign": "center"}}>

              </div>

            </div>

          </div>

        </div>
      )}

      {slides && slides.map((slide) => (

          <div className="single-hero-slide d-flex align-items-center justify-content-center" key={slide.id}>

            <div className="slide-img bg-img"
                 style={{"backgroundImage": `url(${backgroundImage})`}}></div>

            <div className="container">

              <div className="row justify-content-center">

                <div className="col-12 col-lg-9" style={{"textAlign": "center"}}>

                  {/*// Slide Content*/}
                  <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms"
                       onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

                    <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>

                    <h2 data-animation="fadeInUp" data-delay="500ms"
                        dangerouslySetInnerHTML={{__html: slide.title.rendered}}>

                      {/*CURSOS <br/>*/}
                      {/*PROJETO INTEGRA <br/>*/}
                      {/*INÍCIO 05 DE SETEMBRO <br/>*/}

                    </h2>

                    <br/>
                    <br/>
                    {/*<p data-animation="fadeInUp" data-delay="700ms">LOCAL - INOVUERJ<br/>*/}
                    {/*</p>*/}

                    <Link to={`/noticia/${slide.slug}`} className="btn palatin-btn mt-50"
                          data-animation="fadeInUp" data-delay="900ms">Saiba Mais</Link>
                  </div>

                </div>

              </div>

            </div>

          </div>

        )
      )}

    </div>
  );
};

export default OwlCarousel;
