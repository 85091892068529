import React, {useState} from 'react';
import backgroundImageUerjLivro from '../../../Assets/Images/bg-img/bg-1-uerj-livro.jpeg';
import "./HeroArea.scss";
import {WPAllNews} from "../../../Services/WordpressApi";
import {AxiosError} from "axios";
import Preloader from "../Preloader/Preloader";
import {Page} from "../../../Interfaces/IPage";
import OwlCarousel from "../../Plugins/OwlCarousel";

// import OwlCarousel from "../../Plugins/OwlCarousel";

interface HeroAreaProps {
  // items: IMenuItem[]
}

/**
 * Carrossel
 * @constructor
 */
const HeroArea: React.FC<HeroAreaProps> = ({}) => {

  const [slides, setSlides] = React.useState<Page[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);

  React.useEffect(() => {

    const fetchNews = async () => {

      try {
        setLoading(true);
        const news: Page[] | null = await WPAllNews();
        setSlides(news || null);
      } catch (e) {
        setError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    }

    fetchNews();
  }, []);

  if (loading) return <Preloader/>;

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // @ts-ignore
  return (

    <section className="hero-area">

      {/* :: 4.0 Sliders Active Code *!/*/}

      {slides && slides.length > 0 && (
        <OwlCarousel backgroundImage={backgroundImageUerjLivro} slides={slides}/>
      )
      }

    </section>

  );
}

export default HeroArea;
