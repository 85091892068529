import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';

import './MainNews.scss';
import Preloader from "../Preloader/Preloader";
import {WPNewsList} from "../../../Services/WordpressApi";
import {Link} from "react-router-dom"; // Importe o arquivo SCSS
import imageIpeUerj from '../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';

interface MainNewsProps {
  // slug: string;
}

const MainNews: React.FC<MainNewsProps> = ({}) => {
  
  const [news, setNews] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        
        const newsApi = await WPNewsList();

        if (newsApi) {
          setNews(newsApi)
        } else {
          setNews(null)
        }
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
  }, []);
  
  if (loading) {
    return <Preloader/>;
  }
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (
    <>
      
      {news && (
        
        <section className="rooms-area /*section-padding-0-100*/" style={{"marginTop": "-5em"}}>
          
          <div className="container">
            
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6">
                <div className="section-heading text-center">
                  <div className="line-"></div>
                  <Link to="/noticias" className='link-break-news'>
                    <h2>Ultimas Notícias</h2>
                  </Link>
                  <p></p>
                </div>
              </div>
            </div>
            
            <div className="row news-list">
              
              {news.map((item: any, key: number) => (
                <>
                  {
                    // item.slug !== 'prezados-nossos-telefones-estao-com-problemas-estamos-atendendo-por-email-do-inovuerjsr2-uerj-br' && (
                      
                      <div className="col-12 col-md-6 col-lg-4" key={key}>
                        <Link to={`/noticia/${item.slug}`}>
                          <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms"
                               style={{"visibility": "visible", "animationDelay": "100ms", "animationName": "fadeInUp"}}>
                            <div className="bg-thumbnail bg-img"
                                 style={{backgroundImage: `url(${item.imagem_capa ? item.imagem_capa : imageIpeUerj})`}}></div>
                            );
                            <p className="price-from">{item.data}</p>
                            <div className="rooms-text">
                              <div className="line"></div>
                              <h4>{item.titulo}</h4>
                              {/*<p dangerouslySetInnerHTML={{__html: item.titulo}}>*/}
                              {/*Vem aí o 14° Workshop de Ciência Tecnologia e Inovação WCTI. Dias*/}
                              {/*<br/>28/06 - 09:00 às 15:00*/}
                              {/*<br/>29/06 - 09:00 às 15:00*/}
                              {/*</p>*/}
                            </div>
                            {/*<a href="#" className="book-room-btn btn palatin-btn">Ver</a>*/}
                            {/*<Link to={item.} className="book-room-btn btn palatin-btn">Ver</Link>*/}
                          </div>
                        </Link>
                      </div>
                    // )
                  }
                </>
              ))}
              
              <div className="col-12" style={{"paddingBottom": "7em"}}>
                <Link to="/noticias" className='link-break-news'>
                  <div className="load-more-btn text-center wow fadeInUp" data-wow-delay="700ms">
                    <span className="btn palatin-btn">Ver todas</span>
                  </div>
                </Link>
              </div>
            
            </div>
          
          </div>
        
        </section>
      
      )}
    
    
    </>
  );
};

export default MainNews;
