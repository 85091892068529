import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {CONFIG} from './Services/Config';
import './Assets/Scss/style.scss';
import {IMenu, MENU_PRIMARY_ID, MENU_SECUNDARY_ID} from './Interfaces/IMenu';
import HeroArea from "./Components/Sections/HeroArea/HeroArea";
import {fetchMenuItemsById} from "./Services/WordpressApi";
import Preloader from "./Components/Sections/Preloader/Preloader";
import Home from "./Components/Pages/Home/Home";
import AboutUsPage from "./Components/Pages/AboutUs/AboutUsPage";
import JustNewsComponent from "./Components/Pages/News/JustNews/JustNewsComponent";
import AllNewsComponent from "./Components/Pages/News/AllNews/AllNewsComponent";
import GalleryImages from "./Components/Pages/Gallery/GalleryImages/GalleryImages";
import GalleryVideos from "./Components/Pages/Gallery/GalleryVideos/GalleryVideos";
import UerjNumbersPage from "./Components/Pages/UerjNumbers/UerjNumbersPage";
import {RedirectTo} from "./Components/Plugins/RedirectTo";
import NavigationPages from "./Components/Sections/Navigation";


const App: React.FC = () => {
  /**
   * Menu Principal e Secundário sofrendo alterações assíncronas.
   */
  const [menu, setMenu] = useState<IMenu | null>(null);
  const [menuSecundary, setMenuSecundary] = useState<IMenu | null>(null);
  const [effectExecuted, setEffectExecuted] = useState(false);

  /**
   * Aguardando component ser montado para injetar json com menus
   */
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const menuAsyncApi = await fetchMenuItemsById(MENU_PRIMARY_ID);
        setMenu(menuAsyncApi);
        
        const menuSecundaryAsyncApi = await fetchMenuItemsById(MENU_SECUNDARY_ID);
        setMenuSecundary(menuSecundaryAsyncApi);
        
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchMenu();
    
  }, []);

  // Verifica se os menus ainda estão sendo carregados
  if (!menu || !menuSecundary) return <Preloader/>;
  
  return (
    <Router basename={CONFIG.BASENAME}>
      
      {/* Rotas Customizadas ou estáticas ao portal */}
      <Routes>
        
        {/* Página Home */}
        <Route path="/" element={<Home menu={menu} menuSecundary={menuSecundary}/>}/>
        
        {/*/!* Rota para pagina customizada "Quem Somos" *!/*/}
        <Route path="/quem-somos" element={<AboutUsPage menu={menu}/>}/>
        <Route path="/quem-somos-inst" element={<AboutUsPage menu={menu}/>}/>
        
        {/*/!* Rota para exibir uma noticia clicada na "home" *!/*/}
        <Route path="/noticia/:slug" element={<JustNewsComponent menu={menu}/>}/>
        
        {/*/!* Rota para listar TODAS as notícias *!/*/}
        <Route path="/noticias" element={<AllNewsComponent menu={menu}/>}/>

        {/*/!* Rota para listar TODAS as notícias *!/*/}
        <Route path="/galeria-de-imagens" element={<GalleryImages menu={menu}/>}/>
        
        {/*/!* Rota para listar TODAS as notícias *!/*/}
        <Route path="/galeria-de-videos" element={<GalleryVideos menu={menu}/>}/>
        
        {/*/!* Rota para listar uerj em numeros *!/*/}
        <Route path="/uerj-em-numeros" element={<UerjNumbersPage menu={menu}/>}/>

        <Route path="/integra" element={ <RedirectTo to="http://www.inovuerj.sr2.uerj.br/portal/integra" /> } />

      </Routes>
      
      {/*
      Carregamento de rotas e apontamentos de Componentes Page(wordpress api).
      Incluir em 'dontLoadPages' as páginas que não devem ser carregadas. Pois serão criadas com rotas customizadas acima.
      */}
      <NavigationPages dontLoadPages={['quem-somos', 'quem-somos-inst','uerj-em-numeros','galeria-de-imagens']} menuStart={menu}/>
      <NavigationPages dontLoadPages={['galeria-de-imagens','galeria-de-videos']} menuStart={menuSecundary}/>
    
    </Router>
  );
};

export default App;